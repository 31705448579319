import { useState, useMemo } from "react";
// React Router Dom
import { Routes, Route } from "react-router-dom";

// Context
import AppContext from "context/App.context";

// Pages
import Home from "pages/Home/home.page";
import About from "pages/About/about.page";
import Legal from "pages/Legal/legal.page";
import Solutions from "pages/Solutions/solutions.page";
import Navigation from "components/Navigation/navigation.component";
import Products from "pages/Products/products.page";
import GoalBasedInvestment from "pages/Features/GoalBasedInvestment.page";
import SelfTrading from "pages/Features/SelfTrading.page";
import ThematicBaskets from "pages/Features/ThematicBaskets.page";

function App() {
  const [hubSpotOpen, setHubSpotOpen] = useState(false);
  const hubSpotState = useMemo(
    () => ({ hubSpotOpen, setHubSpotOpen }),
    [hubSpotOpen],
  );

  return (
    <AppContext.Provider value={hubSpotState}>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/products/bruno-core" element={<Products />} />
          <Route path="/products/bruno-api" element={<Products />} />
          <Route path="/products/bruno-bespoke" element={<Products />} />
          <Route path="/products/bruno-engine" element={<Products />} />
          {/* <Route path="/solutions" element={<Solutions />} /> */}
          <Route path="/solutions/digital-banks" element={<Solutions />} />
          <Route path="/solutions/brokerage" element={<Solutions />} />
          <Route path="/solutions/advisors" element={<Solutions />} />
          <Route
            path="/features/Goal-Based"
            element={<GoalBasedInvestment />}
          />
          <Route
            path="/features/Thematic-Baskets"
            element={<ThematicBaskets />}
          />
          <Route path="/features/Self-Trading" element={<SelfTrading />} />

          <Route path="/privacy-policy" element={<Legal />} />
          <Route path="/legal-disclaimer" element={<Legal />} />
          <Route path="/terms-of-service" element={<Legal />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
