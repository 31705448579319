import { COLORS } from "styles/theme";

const { styled, Box, Typography, Stack } = require("@mui/material");

export const UserInterfaceContainer = styled(Box)(() => ({
  backgroundColor: COLORS.PRIMARY_BLUE,
  position: "relative",
}));
export const UserInterfaceImg = styled(Stack)(() => ({
  height: "100%",
  width: "100%",
  maxWidth: 600,
  objectFit: "contain",
  margin: "auto",
}));
export const UserInterfaceContentContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(12.5),
}));
export const UserInterfaceDesc = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  paddingRight: theme.spacing(9.75),
  marginTop: theme.spacing(6.07),
  marginBottom: theme.spacing(2.5),
  lineHeight: theme.spacing(5.54),
  color: COLORS.TEXT_WHITE,
  [theme.breakpoints.down("md")]: {
    paddingRight: "initial",
  },
}));
