// Features self trading Component General Styling
import { Box, styled } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export const HeaderFeaturesContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(15),
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  minHeight: "721px",
  position: "relative",
  [theme.breakpoints.up("lg")]: {
    paddingRight: 0,
  },
}));

export const ImgHeader = styled("img")(() => ({
  objectFit: "fill",
  height: "100%",
  width: "100%",
}));
export const HeaderContent = styled(Container)(({ theme }) => ({
  position: "absolute",
  top: "17%",
  left: "0",
  marginLeft: theme.spacing(11),
  "@media (max-width: 1265px)": {
    marginLeft: 0,
    top: 0,
  },
}));
export const TypographyDes = styled(Typography)(() => ({
  fontSize: "1.5rem",
  lineHeight: "170%",
  "@media (max-width: 544px)": {
    lineHeight: "170%",
    fontSize: "1.25rem",
  },
  "@media (max-width: 425px)": {
    lineHeight: "initial",
    fontSize: "1.25rem",
  },
}));
export const TypographyTitle = styled(Typography)(() => ({
  fontSize: "7.375rem",
  lineHeight: "11.375rem",
  "@media (max-width: 1439px)": {
    fontSize: "5rem",
  },
  "@media (max-width: 767px)": {
    lineHeight: "initial",
  },
}));
