import React from "react";
import { Box } from "@mui/material";
import { FeatDesc, FeatTitle, ImgIconFeat } from "./features.style";

function Feature({ img, title, content }) {
  return (
    <Box textAlign="center">
      <ImgIconFeat src={img.src} />
      <FeatTitle variant="subtitle1" mt="2">
        {title}
      </FeatTitle>
      <FeatDesc variant="subtitle2">{content}</FeatDesc>
    </Box>
  );
}

export default Feature;
