import { Box } from "@mui/material";
import Features from "components/FeaturesContent/features/Features.component";
import ConclusionSteps from "components/FeaturesContent/conclusionSteps/ConclusionSteps.component";
import HeaderFeatures from "components/FeaturesContent/headerFeatures/HeaderFeatures.component";
import MarketIntegration from "components/FeaturesContent/marketIntegration/MarketIntegration.component";
import React from "react";
import SECTIONS from "shared/sections";
import Benefits from "components/FeaturesContent/benefits/Benefits.component";
import UseCase from "components/FeaturesContent/useCase/UseCase.component";

const {
  THEMATIC_BASKET_HEADER,
  THEMATIC_BASKET_MARKET_DATA,
  THEMATIC_BASKET_FEATURES,
  THEMATIC_BASKET_ANALYTIC,
  THEMATIC_BASKET_REGIONAL,
  THEMATIC_BASKET_BENEFIT,
  THEMATIC_BASKET_USECASE,
} = SECTIONS;
function ThematicBaskets() {
  return (
    <Box>
      <HeaderFeatures sectionData={THEMATIC_BASKET_HEADER} />
      <MarketIntegration sectionData={THEMATIC_BASKET_MARKET_DATA} />
      <Features sectionData={THEMATIC_BASKET_FEATURES} />
      <ConclusionSteps sectionData={THEMATIC_BASKET_ANALYTIC} />
      <Features sectionData={THEMATIC_BASKET_REGIONAL} />
      <Benefits sectionData={THEMATIC_BASKET_BENEFIT} />
      <UseCase sectionData={THEMATIC_BASKET_USECASE} />
    </Box>
  );
}

export default ThematicBaskets;
