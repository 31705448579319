import SECTIONS from "shared/sections";

import { COLORS } from "styles/theme";

const { styled, Box, Typography } = require("@mui/material");

const { FEATURES_SECTION_CONCLUSION } = SECTIONS;

export const ConclusionStepsContainer = styled(Box)(() => ({
  position: "relative",
  minHeight: "530px",
  backgroundImage: `url(${FEATURES_SECTION_CONCLUSION.bgImg.src})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

export const ConclusionContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));
export const ImgIconConclusion = styled("img")(() => ({
  borderRadius: "50%",
  width: "109px",
  height: "109px",
  padding: "1.25rem",
}));
export const ConclusionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN Pro",
  fontSize: "1.5rem",
  color: COLORS.TEXT_BLACK,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
export const ConclusionDesc = styled(Typography)(() => ({
  fontSize: "1.125rem",
  textAlign: "center",
  lineHeight: "32.94px",
}));
