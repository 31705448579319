import { Box } from "@mui/material";
import ConclusionSteps from "components/FeaturesContent/conclusionSteps/ConclusionSteps.component";
import Features from "components/FeaturesContent/features/Features.component";
import HeaderFeatures from "components/FeaturesContent/headerFeatures/HeaderFeatures.component";
import MarketIntegration from "components/FeaturesContent/marketIntegration/MarketIntegration.component";
import SECTIONS from "shared/sections";

const {
  FEATURES_SECTION_DATA_INTEGRATION,
  FEATURES_SELF_TRADING_HEADER,
  FEATURES_SECTION_CONCLUSION,
  FEATURES_SECTION_FEATURE,
} = SECTIONS;

function SelfTrading() {
  return (
    <Box>
      <HeaderFeatures sectionData={FEATURES_SELF_TRADING_HEADER} />
      <Features sectionData={FEATURES_SECTION_FEATURE} />
      <MarketIntegration sectionData={FEATURES_SECTION_DATA_INTEGRATION} />
      <ConclusionSteps sectionData={FEATURES_SECTION_CONCLUSION} />
    </Box>
  );
}

export default SelfTrading;
