// MUI
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const FooterContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  marginTop: "auto",
  minHeight: "5.5rem",
  direction: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(4),
  },
}));

export const FooterTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN Pro",
  fontWeight: 900,
  color: "#fff",
  [theme.breakpoints.down("sm")]: {
    fontSize: ".9rem",
  },
}));
