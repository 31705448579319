import { useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppContext from "context/App.context";
import { COLORS } from "styles/theme";

// const hubSpotEmbedded = ``;

function HubSpot() {
  const { hubSpotOpen, setHubSpotOpen } = useContext(AppContext);
  const hubSpotModalRef = useRef();

  useEffect(() => {
    if (window.hbspt && hubSpotOpen) {
      window.hbspt.forms.create({
        region: "eu1",
        portalId: "26232347",
        formId: "25350f7f-81e4-4e89-b43c-4d4619c62139",
        target: "#hubSpot-modal",
      });
    }
  }, [hubSpotOpen]);

  return (
    <Modal
      open={hubSpotOpen}
      onClose={() => setHubSpotOpen(false)}
      aria-labelledby="modal-contactUs"
      aria-describedby="modal-contact-form"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <>
        <IconButton
          sx={{
            position: "absolute",
            right: "2%",
            top: "1%",
          }}
          onClick={() => setHubSpotOpen(false)}
        >
          <CloseIcon sx={{ color: COLORS.TEXT_WHITE, fontSize: "4rem" }} />
        </IconButton>
        <Box
          ref={hubSpotModalRef}
          sx={{
            width: "50rem",
            maxHeight: "90vh",
            overflow: "auto",
            backgroundColor: COLORS.TEXT_WHITE,
            padding: 4,
          }}
          id="hubSpot-modal"
        />
      </>
    </Modal>
  );
}

export default HubSpot;
