import { Box, styled } from "@mui/material";

export const InvestmentContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));
export const InvestmentContent = styled(Box)(({ theme }) => ({
  fontSize: "1.5rem",
  lineHeight: "2.745rem",
  marginTop: "3rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    marginTop: "2rem",
  },
}));
