import styled from "@emotion/styled";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { COLORS } from "styles/theme";

import IMAGES from "shared/images";

export const CeoContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
}));
export const CeoContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: COLORS.PRIMARY_BLUE,
  backgroundImage: `url(${IMAGES.GOAL_BASED_CEO_BACKGROUND.src})`,
  backgroundPosition: "19% 0%",
  backgroundRepeat: "no-repeat",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  minHeight: "485px",
  boxShadow: "0px 10px 200px rgba(21, 21, 21, 0.15)",
}));
export const CeoContentImg = styled(Box)(({ theme }) => ({
  position: useMediaQuery(theme.breakpoints.down("lg")) ? "static" : "absolute",
  top: "-18px",
  right: 0,
}));
export const CeoContentImgCeo = styled("img")(() => ({
  width: "100%",
  height: "auto",
}));
export const CeoContentDesc = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5),
  paddingLeft: useMediaQuery(theme.breakpoints.down("sm"))
    ? "initial"
    : theme.spacing(6),
  paddingRight: useMediaQuery(theme.breakpoints.down("sm"))
    ? "initial"
    : theme.spacing(6),
  textAlign: "center",
  position: useMediaQuery(theme.breakpoints.down("lg")) ? "static" : "absolute",
  top: theme.spacing(13),
  maxWidth: "442px",
  left: theme.spacing(30.875),
}));
export const CeoContentDescTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.656rem",
  fontWeight: "700",
  color: COLORS.TEXT_BLACK,
}));
export const CeoContentDescSubtitle = styled(Typography)(({ theme }) => ({
  color: COLORS.TEXT_YELLOW,
}));
export const CeoContentDescContent = styled(Typography)(({ theme }) => ({
  color: COLORS.TEXT_BLACK,
  marginTop: theme.spacing(3),
}));
