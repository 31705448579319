// MUI
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material";
// Shared
import { COLORS } from "styles/theme";

export const NavbarContainer = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "trigger",
})(({ trigger, theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2, 0),
  ...(trigger && {
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backgroundColor: COLORS.TEXT_WHITE,
  }),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 0.5),
  },
}));

export const NavbarLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive, theme }) => ({
  color: COLORS.TEXT_BLACK,
  textTransform: "capitalize",
  fontFamily: "DIN Pro",
  fontWeight: 900,
  position: "relative",
  transition: "all .2s",
  fontSize: "1.2rem",
  cursor: "pointer",
  "&::after": {
    content: '""',
    width: "100%",
    position: "absolute",
    left: -1,
    bottom: 0,
    height: "2px",
    backgroundColor: theme.palette.primary.main,
    transition: "opacity .2s",
    opacity: isActive ? 1 : 0,
  },

  [theme.breakpoints.down("lg")]: {
    marginRight: theme.spacing(6),
    fontSize: "1rem",
  },
}));
