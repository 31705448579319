import { Box, Typography, styled } from "@mui/material";
import { BORDERS } from "styles/theme";

export const UseCaseContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  border: BORDERS.PRODUCT_USE_CASE_CONTENT_BOX,
  borderRadius: "16px",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 1),
  },
}));
export const UseCaseContent = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "2rem",
  lineHeight: 2,
  fontFamily: "Nexa Light",
}));
