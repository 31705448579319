import { useLocation } from "react-router-dom";

// MUI
import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";

// Shared
import IMAGES from "shared/images";
import {
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  BenefitContainer,
  ProductContent,
  ProductImage,
  ProductTitle,
  UseCaseContent,
  UseCaseContentContainer,
} from "./productDetail.styles";
// Styles
// import // ProductBenefit,
// // ProductBenefitHeader,
// // ProductImage,
// // ProductTitle,
// "./productDetail.styles";

const BrunoProductList = {
  "bruno-core": {
    title: `Bruno Core`,
    content: `Bruno Core is a pre-built, ready-to-use platform designed for fast and easy deployment. It is ideal for fintech start-ups and companies looking for a quick MVP roll-out in less than 30 days. With Bruno Core, you can quickly launch a digital investment platform with multi-asset trading capabilities, multiple investment journeys and a wide range of customization options. Additionally, Bruno Core is built on Azure, which provides the ability to scale seamlessly and handle increased traffic, as well as the ability to integrate with legacy systems through our open API architecture.`,
    benefits: [
      {
        text: `Quick MVP roll-out in less than 30 days`,
        Icon: IMAGES.PRODUCTS_BRUNO_CORE_BENEFITS_ICON_1_SVG,
      },
      {
        text: `Ready out of the box with Pre-built functionality`,
        Icon: IMAGES.PRODUCTS_BRUNO_CORE_BENEFITS_ICON_2_SVG,
      },
      {
        text: `Scalable architecture powered by Microsoft Azure cloud services`,
        Icon: IMAGES.PRODUCTS_BRUNO_CORE_BENEFITS_ICON_3_SVG,
      },
      {
        text: `Brandable and Customizable`,
        Icon: IMAGES.PRODUCTS_BRUNO_CORE_BENEFITS_ICON_4_SVG,
      },
      {
        text: `Available on Web Based, IOS and Android Mobile Devices`,
        Icon: IMAGES.PRODUCTS_BRUNO_CORE_BENEFITS_ICON_5_SVG,
      },
    ],
    useCaseTitle: "Fintech Startup",
    useCaseContent: `A fintech start-up wants to launch a digital investment platform in less than 30 days with multi-asset trading capabilities and self-trading options. Bruno Core is the perfect solution as it is pre-built and ready-to-use, allowing for a quick MVP roll-out and easy customization options to align with the start-up's brand guidelines.`,
    useCaseMedia: IMAGES.PRODUCTS_USE_CASE,
  },
  "bruno-api": {
    title: `Bruno API`,
    content: `Bruno API is the perfect solution for businesses looking to expand their offerings and provide their customers with a wider range of investment options. This product allows you to integrate our platform directly into your existing application, providing you with the ability to offer thematic baskets, goal-based planning, and other investment options to your customers.`,
    benefits: [
      {
        text: `Seamless integration into existing application`,
        Icon: IMAGES.PRODUCTS_BRUNO_API_BENEFITS_ICON_1_SVG,
      },
      {
        text: `Easy onboarding and fast deployment`,
        Icon: IMAGES.PRODUCTS_BRUNO_API_BENEFITS_ICON_2_SVG,
      },
      {
        text: `Increased customer engagement and retention`,
        Icon: IMAGES.PRODUCTS_BRUNO_API_BENEFITS_ICON_3_SVG,
      },
      {
        text: `Ability to offer a wider range of investment options`,
        Icon: IMAGES.PRODUCTS_BRUNO_API_BENEFITS_ICON_4_SVG,
      },
      {
        text: `Customizable to meet specific business requirements`,
        Icon: IMAGES.PRODUCTS_BRUNO_API_BENEFITS_ICON_5_SVG,
      },
    ],
    useCaseTitle: "Payment Super App",
    useCaseContent: `A payment super app wants to extend their offerings to include thematic baskets and goal-based planning to their existing customers. By integrating Bruno API, they are able to provide a seamless investment experience for their customers, all within their existing application. With Bruno API, the payment super app is able to offer a wider range of investment options, increasing customer engagement and retention.`,
    useCaseMedia: IMAGES.PRODUCTS_USE_CASE,
  },
  "bruno-bespoke": {
    title: `Bruno Bespoke`,
    content: `Bruno Bespoke is the perfect solution for businesses that require a fully customized solution that is tailored to their specific needs and requirements. This solution offers complete flexibility and control, with source code ownership that allows businesses to make any changes they need to ensure the perfect fit for their business model.`,
    benefits: [
      {
        text: `Tailored solution to meet specific business requirements`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_1_SVG,
      },
      {
        text: `Source code ownership for added flexibility and control`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_2_SVG,
      },
      {
        text: `End-to-end digital journey for customers`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_3_SVG,
      },
      {
        text: `Integration with existing systems and infrastructure`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_4_SVG,
      },
      {
        text: `Customized branding and user experience`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_5_SVG,
      },
      {
        text: `Option for local integration and localization`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_6_SVG,
      },
      {
        text: `Scalability to handle increased traffic`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_7_SVG,
      },
      {
        text: `Potential for cost savings and increased revenue`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_8_SVG,
      },
      {
        text: `Enhanced security and compliance`,
        Icon: IMAGES.PRODUCTS_BRUNO_BESPOKE_BENEFITS_ICON_9_SVG,
      },
    ],
    useCaseTitle: "Corporate Pension Provider",
    useCaseContent: `One use case of Bruno Bespoke is a Pension provider that wants to offer its customers a fully customized application that is tailored to their specific needs. With Bruno Bespoke, the provider can expect a solution that is specifically designed to meet the requirements of the pension industry, including mandatory and non-mandatory structures. Additionally, the source code ownership allows the pension provider to make any necessary changes to the application to ensure it is fully compliant with local regulations and fits seamlessly into their existing processes.`,
    useCaseMedia: IMAGES.PRODUCTS_USE_CASE,
  },
  "bruno-engine": {
    title: `Bruno Engine`,
    content: `Bruno Engine is a leasing solution for businesses looking for a powerful Order Management System (OMS) to connect directly to exchanges and market data providers. This solution is ideal for digital banks and other financial institutions that want to offer real-time trading and portfolio management to their customers.`,
    benefits: [
      {
        text: `Advanced OMS capabilities for multi-asset trading and portfolio management`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_1_SVG,
      },
      {
        text: `Highly customizable and configurable to meet specific business needs`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_2_SVG,
      },
      {
        text: `Direct connection to exchanges and market data providers for real-time data and trading`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_3_SVG,
      },
      {
        text: `Advanced rebalancing capabilities for optimal portfolio management`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_4_SVG,
      },
      {
        text: `Advanced order routing for improved execution and cost reduction`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_5_SVG,
      },
      {
        text: `Pre-built integrations with marketplace applications for added convenience and efficiency`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_6_SVG,
      },
      {
        text: `Built-in ecosystem management for streamlined integration with other systems and platforms`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_7_SVG,
      },
      {
        text: `Leasing model for added flexibility and cost-effectiveness`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_8_SVG,
      },
      {
        text: `Advanced reporting and analytics capabilities for improved decision-making`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_9_SVG,
      },
      {
        text: `Automated daily settlements and corporate actions`,
        Icon: IMAGES.PRODUCTS_BRUNO_ENGINE_BENEFITS_ICON_10_SVG,
      },
    ],
    useCaseTitle: "Digital Bank",
    useCaseContent: `A digital bank wants to offer its customers a seamless online trading experience for multiple asset classes. The bank chooses Bruno Engine as its OMS solution, leasing the system to connect directly to exchanges and market data providers. This allows the bank to offer real-time data and trading to its customers, while also having advanced portfolio management capabilities. The leasing model also proves to be cost-effective for the bank.`,
    useCaseMedia: IMAGES.PRODUCTS_USE_CASE,
  },
};

function getProductBenefits(benefitList, productId) {
  return benefitList.map((benefit, index) => {
    const key = `${productId}-content-${index} `;
    return (
      <Grid key={key} item xs={12} md={6}>
        <BenefitContainer>
          <Box component={benefit.Icon} />
          <Typography variant="body1">{benefit.text}</Typography>
        </BenefitContainer>
      </Grid>
    );
  });
}
// function getProductContent(contentList, productId) {
//   return contentList.map((content, index) => {
//     const key = `${productId}-content-${index} `;
//     return (
//       <Typography
//         key={key}
//         sx={{ fontSize: "1.5rem", lineHeight: 1.8, textAlign: "justify" }}
//         paragraph
//       >
//         {content}
//       </Typography>
//     );
//   });
// }

function getPath(fullPath) {
  const pathArray = fullPath.split("/"); // ["" , "solutions", ""] or ["", "solutions" , "brokerage", ""]
  const path = pathArray[pathArray.length - 2]; // solutions or brokerage
  return path;
}

function ProductDetail() {
  const location = useLocation();
  const product = BrunoProductList[getPath(location.pathname)];
  const {
    HOME_HERO_BRUNO_LOGO_SVG,
    PRODUCTS_HERO_PROTOTYPE,
    PRODUCTS_BENEFITS_BG_SHAPE_RED_SVG,
    PRODUCTS_BENEFITS_BG_SHAPE_GREEN_SVG,
    PRODUCTS_USE_CASE,
  } = IMAGES;
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Stack sx={{ paddingY: 18, gap: 8 }}>
      {/* Content */}
      <Container maxWidth="xl" component="section">
        <Grid container direction={matchesSM ? "column-reverse" : "row"}>
          <Grid item xs={12} md={6}>
            <Stack gap={2} sx={{ pt: 4 }}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box component={HOME_HERO_BRUNO_LOGO_SVG} />
                <ProductTitle variant="h1">{product.title}</ProductTitle>
              </Stack>
              <ProductContent>{product.content}</ProductContent>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <ProductImage
              src={PRODUCTS_HERO_PROTOTYPE.src}
              alt={PRODUCTS_HERO_PROTOTYPE.alt}
            />
          </Grid>
        </Grid>
      </Container>
      <Box component="section" sx={{ position: "relative" }}>
        <Box
          component={PRODUCTS_BENEFITS_BG_SHAPE_RED_SVG}
          sx={{ position: "absolute", zIndex: -1 }}
        />
        <Box
          component={PRODUCTS_BENEFITS_BG_SHAPE_GREEN_SVG}
          sx={{
            position: "absolute",
            right: "10%",
            top: { xs: "52%", lg: "20%" },
            zIndex: -1,
          }}
        />
        <Container maxWidth="xl">
          {/* Content */}
          <Grid container justifyContent="center" rowGap={8} columnSpacing={4}>
            {getProductBenefits(product.benefits)}
          </Grid>
        </Container>
      </Box>
      <Box component="section">
        <Container maxWidth="xl">
          <Grid container justifyContent="center" rowGap={8} columnSpacing={4}>
            <Grid item xs={12} md={6}>
              <Stack justifyContent="center" sx={{ height: "100%" }}>
                <Typography variant="h1" component="h2">
                  Use Case
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      xs: "3rem",
                      sm: "5rem",
                      lg: "7.5rem",
                    },
                  }}
                >
                  {product.useCaseTitle}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={PRODUCTS_USE_CASE.src}
                alt={PRODUCTS_USE_CASE.alt}
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
          <UseCaseContentContainer>
            <UseCaseContent>{product.useCaseContent}</UseCaseContent>
          </UseCaseContentContainer>
        </Container>
      </Box>
    </Stack>
  );
}

export default ProductDetail;
