import { Outlet } from "react-router-dom";
// Components
import Footer from "components/Footer/footer.component";
import Navbar from "components/Navbar/navbar.component";
import customTheme from "styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import HubSpot from "components/HubSpot/hubSpot.component";

function Navigation() {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Navbar />
      <Box component="main">
        <Outlet />
      </Box>
      <HubSpot />
      <Footer />
    </ThemeProvider>
  );
}

export default Navigation;
