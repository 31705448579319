import { Container, Typography, styled } from "@mui/material";
import { COLORS } from "styles/theme";
// Features Component General Styling
export const FeaturesContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(12),
  position: "relative",
  [theme.breakpoints.up("lg")]: {
    paddingRight: 0,
  },
}));

export const ImgIconFeat = styled("img")(() => ({
  width: "109px",
  height: "109px",
  padding: "20px",
}));
export const FeatTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  color: COLORS.TEXT_BLACK,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
export const FeatDesc = styled(Typography)(() => ({
  fontSize: "1.25rem",
  textAlign: "center",
}));
