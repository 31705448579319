import { Box, Grid } from "@mui/material";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import React from "react";
import {
  ConclusionContent,
  ConclusionDesc,
  ConclusionStepsContainer,
  ConclusionTitle,
  ImgIconConclusion,
} from "./conclusionSteps.style";

function ConclusionSteps({ sectionData }) {
  return (
    <Box>
      <ConclusionStepsContainer>
        {sectionData.mainTitle && (
          <SectionTitle
            title={sectionData.mainTitle}
            PT="3rem"
            maxWidth="75%"
          />
        )}
        <ConclusionContent>
          <Grid container justifyContent="center">
            <Grid item md={6}>
              <Box textAlign="center">
                <ImgIconConclusion src={sectionData.img.src} />
                <ConclusionTitle variant="subtitle1" mt="2">
                  {sectionData.title}
                </ConclusionTitle>
                <ConclusionDesc variant="subtitle2">
                  {sectionData.desc}
                </ConclusionDesc>
              </Box>
            </Grid>
          </Grid>
        </ConclusionContent>
      </ConclusionStepsContainer>
    </Box>
  );
}

export default ConclusionSteps;
