import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { COLORS } from "styles/theme";
import IMAGES from "shared/images";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import {
  ArchitectureDescriptionCard,
  ListText,
  MarkIcon,
} from "./benefits.style";

function makeListContent(card) {
  return card.content?.map((item) => (
    <ListItem disablePadding key={item}>
      <ListItemButton sx={{ alignItems: "baseline" }}>
        <ListItemIcon sx={{ minWidth: "30px" }}>
          <MarkIcon fontSize="small" />
        </ListItemIcon>
        <ListText primary={item} />
      </ListItemButton>
    </ListItem>
  ));
}
function getSolutionArchitectureCards(cardsList, breakpoint) {
  return cardsList.map((card, index) => {
    const key = `${card.title}-${index}`;
    let flexDirection = "row";
    if (breakpoint) flexDirection = "column";
    else if (index % 2 !== 0) flexDirection = "row-reverse";
    return (
      <Grid
        key={key}
        container
        direction={flexDirection}
        sx={{
          paddingY: { xs: 4, sm: 12 },
          "&:hover": {
            "& .architecture-card": {
              left: 0,
            },
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: "relative",
            minHeight: { md: 365 },
          }}
        >
          <ArchitectureDescriptionCard
            justifyContent="center"
            className="architecture-card"
            cardIndex={index}
          >
            {/* <Typography variant="subtitle2"> {card.content}</Typography> */}
            <List>{makeListContent(card)}</List>
          </ArchitectureDescriptionCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            sx={{
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={card.media.src}
              alt={card.media.alt}
              sx={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
            <Typography
              variant="h1"
              sx={{
                maxWidth: "22rem",
                position: "absolute",
                color: COLORS.TEXT_GREY3,
              }}
            >
              {card.title}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    );
  });
}
function Benefits({ sectionData }) {
  const {
    SOLUTION_ARCHITECTURE_BG_SHAPE_GREEN_SVG,
    SOLUTION_ARCHITECTURE_BG_SHAPE_RED_SVG,
    SOLUTION_ARCHITECTURE_BG_SHAPE_YELLOW_SVG,
  } = IMAGES;
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box sx={{ position: "relative", paddingY: 8 }}>
      <SectionTitle title={sectionData.mainTitle} />
      <Box
        component={SOLUTION_ARCHITECTURE_BG_SHAPE_RED_SVG}
        sx={{ position: "absolute", left: 0, top: "2%", maxWidth: "100%" }}
      />
      <Box
        component={SOLUTION_ARCHITECTURE_BG_SHAPE_YELLOW_SVG}
        sx={{ position: "absolute", right: 0, top: "40%", maxWidth: "100%" }}
      />
      <Box
        component={SOLUTION_ARCHITECTURE_BG_SHAPE_GREEN_SVG}
        sx={{ position: "absolute", left: 0, top: "86%", maxWidth: "100%" }}
      />
      <Container maxWidth="xl">
        {getSolutionArchitectureCards(sectionData.cards, matchesSM)}
      </Container>
    </Box>
  );
}

export default Benefits;
