import { Box, Stack, Typography, styled } from "@mui/material";
import { BORDERS, COLORS, SHADOWS } from "styles/theme";

export const ProductTitle = styled(Typography)(() => ({
  fontFamily: "DIN Pro",
  fontWeight: 700,
  color: COLORS.PRIMARY_YELLOW,
  fontSize: "4.375rem",
}));

export const ProductContent = styled(Typography)(() => ({
  color: COLORS.PRIMARY_BLUE,
  fontWeight: 400,
  fontSize: "1.5rem",
}));

export const ProductImage = styled("img")(() => ({
  maxHeight: "45rem",
  maxWidth: "50rem",
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

export const BenefitContainer = styled(Stack)(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(2, 4),
  flexDirection: "row",
  alignItems: "center",
  boxShadow: SHADOWS.PRODUCT_BENEFIT_BOX,
  border: BORDERS.PRODUCT_BENEFIT_BOX,
  borderRadius: "16px",
  gap: theme.spacing(4),
  backgroundColor: COLORS.TEXT_WHITE,
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
}));

export const UseCaseContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  border: BORDERS.PRODUCT_USE_CASE_CONTENT_BOX,
  borderRadius: "16px",
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 1),
  },
}));

export const UseCaseContent = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "2rem",
  lineHeight: 2,
}));
