import Button from "@mui/material/Button";
import { HashLink } from "react-router-hash-link";

function DemoButton() {
  return (
    <Button
      variant="outlined"
      color="primary"
      size="medium"
      sx={{
        marginRight: 1,
        backgroundColor: "rgb(255,255,255,0.7)",
        width: "100%",
      }}
      component={HashLink}
      to="/#contact"
    >
      Demo
    </Button>
  );
}

export default DemoButton;
