import { Box } from "@mui/material";
import Ceo from "components/FeaturesContent/ceo/Ceo.component";
import ConclusionSteps from "components/FeaturesContent/conclusionSteps/ConclusionSteps.component";
import GettingStart from "components/FeaturesContent/gettingStart/GettingStart.component";
import HeaderFeatures from "components/FeaturesContent/headerFeatures/HeaderFeatures.component";
import Investment from "components/FeaturesContent/investment/Investment.component";
import UseCase from "components/FeaturesContent/useCase/UseCase.component";
import UserInterface from "components/FeaturesContent/userInterface/UserInterface.component";
import React from "react";

import SECTIONS from "shared/sections";

const {
  GOAL_BASED_HEADER,
  GOAL_BASED_TYPES_GOALS,
  GOAL_BASED_USE_CASE,
  GOAL_BASED_USER_INTERFACE,
  GOAL_BASED_INVESTMENT,
  GOAL_BASED_GETTING_STARTED,
  GOAL_BASED_CEO,
} = SECTIONS;
function GoalBasedInvestment() {
  return (
    <Box>
      <HeaderFeatures sectionData={GOAL_BASED_HEADER} />
      <ConclusionSteps sectionData={GOAL_BASED_TYPES_GOALS} />
      <UseCase sectionData={GOAL_BASED_USE_CASE} />
      <UserInterface sectionData={GOAL_BASED_USER_INTERFACE} />
      <Ceo sectionData={GOAL_BASED_CEO} />
      <Investment sectionData={GOAL_BASED_INVESTMENT} />
      <GettingStart sectionData={GOAL_BASED_GETTING_STARTED} />
    </Box>
  );
}

export default GoalBasedInvestment;
