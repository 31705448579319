// React
import { useState, cloneElement, useEffect, Fragment } from "react";
// React Router Dom
import { useLocation } from "react-router-dom";
// React Router Hash
import { HashLink } from "react-router-hash-link";
// Component
import DemoButton from "components/Button/button.component";

// MUI
import {
  Menu,
  MenuItem,
  useScrollTrigger,
  Box,
  Container,
  Stack,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
  IconButton,
  ListItem,
  List,
  Divider,
  Typography,
} from "@mui/material";

// Shared
import IMAGES from "shared/images";

// Styles
import { NavbarContainer, NavbarLink } from "./navbar.styles";

// TODO:
// Add Drawer for small screens
// Make It Responsive

const MenuLinksData = [
  { name: "Digital Banks", url: "/solutions/digital-banks/#" },
  { name: "Brokerage", url: "/solutions/brokerage/#" },
  { name: "Advisors", url: "/solutions/advisors/#" },
];
const MenuLinksFeaturesSelfTrading = [
  { name: "Self-Trading", url: "/features/Self-Trading/#" },
  { name: "Thematic Baskets", url: "/features/Thematic-Baskets/#" },
  { name: "Goal-Based Investment", url: "/features/Goal-Based/#" },
];
const NavLinksData = [
  {
    name: "Bruno",
    url: "/#",
  },

  // {
  //   name: "Products",
  //   url: "/products/#",
  // },
  {
    name: "Brokerage",
    url: "/solutions/brokerage/#",
  },
  {
    name: "Advisors",
    url: "/solutions/advisors/#",
  },
  {
    name: "Digital Banks",
    url: "/solutions/digital-banks/#",
  },
  {
    name: "About Us",
    url: "/about-us/#",
  },
  // {
  //   name: "Blog",
  //   url: "https://d2bsyv6s3rigqu.cloudfront.net/",
  // },
];

function getDrawerLinks(drawerLinksList, toggleDrawer) {
  return drawerLinksList.map((drawerLink) => {
    if (drawerLink.name === "Solutions") {
      return (
        <ListItem key={drawerLink.name}>
          <Stack>
            <Typography variant="body1" color="primary">
              Solutions
            </Typography>
            {/* Recursive Call to render Solutions Pages */}
            <List>{getDrawerLinks(MenuLinksData, toggleDrawer)}</List>
          </Stack>
        </ListItem>
      );
    }
    if (drawerLink.name === "Features") {
      return (
        <ListItem key={drawerLink.name}>
          <Stack>
            <Typography variant="body1" color="primary">
              Features
            </Typography>
            {/* Recursive Call to render Solutions Pages */}
            <List>
              {getDrawerLinks(MenuLinksFeaturesSelfTrading, toggleDrawer)}
            </List>
          </Stack>
        </ListItem>
      );
    }
    return (
      <Fragment key={drawerLink.name}>
        <ListItem
          sx={{ marginY: 1 }}
          component={HashLink}
          to={drawerLink.url}
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          {drawerLink.name}
        </ListItem>
        <Divider />
      </Fragment>
    );
  });
}

function getMenuLinks(menuLinksList, handleClose) {
  return menuLinksList.map((menuLink) => {
    return (
      <MenuItem
        component={HashLink}
        to={menuLink.url}
        key={menuLink.name}
        onClick={handleClose}
      >
        {menuLink.name}
      </MenuItem>
    );
  });
}

function getNavLinks(navLinksList, activeLink, handleMenu) {
  return navLinksList.map((navLink) => {
    if (navLink.name === "Solutions") {
      return (
        <NavbarLink
          key={navLink.name}
          onClick={handleMenu}
          isActive={activeLink === navLink.url}
        >
          {navLink.name}
        </NavbarLink>
      );
    }
    if (navLink.name === "Features") {
      return (
        <NavbarLink
          key={navLink.name}
          onClick={handleMenu}
          isActive={activeLink === navLink.url}
        >
          {navLink.name}
        </NavbarLink>
      );
    }
    return (
      <NavbarLink
        component={HashLink}
        key={navLink.name}
        isActive={activeLink === navLink.url}
        to={navLink.url}
      >
        {navLink.name}
      </NavbarLink>
    );
  });
}

function ElevationScroll(props) {
  const { children } = props;

  return cloneElement(children, {
    elevation: props.trigger ? 4 : 0,
  });
}

function Navbar() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const initialLink = NavLinksData[0].url;
  const [activeLink, setActiveLink] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorText, setAnchorText] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const matchesDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const { TS_LOGO, NAV_MENU_ICON_SVG } = IMAGES;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorText(event.currentTarget.text);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer((prev) => !prev);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const sectionElement = document.querySelector(location.hash);
      if (sectionElement) {
        setTimeout(
          () => sectionElement.scrollIntoView({ behavior: "smooth" }),
          1000,
        );
      }
    }
    const currentLink = NavLinksData.find((link) => {
      return link.url === `${location.pathname}#`;
    });

    if (currentLink) {
      setActiveLink(currentLink.url);
    }
  }, [location]);
  return (
    <>
      <ElevationScroll trigger={trigger}>
        <NavbarContainer component="nav" trigger={trigger}>
          <Container maxWidth="xl">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={4}
            >
              <HashLink
                to={initialLink}
                onClick={() => setActiveLink(initialLink)}
              >
                <Box
                  component="img"
                  src={TS_LOGO.src}
                  alt={TS_LOGO.alt}
                  sx={{
                    width: { xs: 180, sm: "initial" },
                  }}
                />
              </HashLink>

              {matchesDownMD ? (
                <IconButton onClick={toggleDrawer}>
                  <Box component={NAV_MENU_ICON_SVG} />
                </IconButton>
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ gap: { xs: 0, lg: 6 } }}
                >
                  {getNavLinks(NavLinksData, activeLink, handleMenu)}
                  <Stack sx={{ flexDirection: { lg: "row" }, gap: 1 }}>
                    <DemoButton />
                    {/* <Button
                      variant="contained"
                      component={HashLink}
                      color="primary"
                      size="medium"
                      to="/about-us/#contact-us"
                    >
                      Contact Us
                    </Button> */}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Container>
        </NavbarContainer>
      </ElevationScroll>
      {/* Menu */}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {anchorText === "Solutions"
          ? getMenuLinks(MenuLinksData, handleClose)
          : getMenuLinks(MenuLinksFeaturesSelfTrading, handleClose)}
      </Menu>

      {/* Drawer */}
      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <List role="presentation" sx={{ width: "240px" }}>
          {getDrawerLinks(NavLinksData, toggleDrawer)}
          <ListItem onClick={toggleDrawer} onKeyDown={toggleDrawer}>
            <DemoButton />
          </ListItem>
          <Divider />
          {/* <ListItem onClick={toggleDrawer} onKeyDown={toggleDrawer}>
            <Button
              variant="contained"
              component={HashLink}
              color="primary"
              size="medium"
              to="/about-us/#contact-us"
              fullWidth
            >
              Contact Us
            </Button>
          </ListItem> */}
        </List>
      </SwipeableDrawer>
    </>
  );
}

export default Navbar;
