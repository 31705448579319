//  Router
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// MUI
import { IconButton, Divider, Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

// Theme
import { COLORS } from "styles/theme";
// Styles
import { FooterContainer, FooterTypography } from "./footer.styles";

const FOOTER_SOCIAL_LINKS = [
  {
    icon: <FacebookIcon sx={{ color: COLORS.TEXT_WHITE }} />,
    link: "https://m.facebook.com/Tradesocio/?mibextid=LQQJ4d",
  },
  {
    icon: <TwitterIcon sx={{ color: COLORS.TEXT_WHITE }} />,
    link: "https://twitter.com/tradesocio?s=21&t=aZG3XWs5Blwt5GniMQLZwA",
  },
  {
    icon: <LinkedInIcon sx={{ color: COLORS.TEXT_WHITE }} />,
    link: "https://www.linkedin.com/company/tradesocio/",
  },
  {
    icon: <YouTubeIcon sx={{ color: COLORS.TEXT_WHITE }} />,
    link: "https://youtube.com/@tradesocio",
  },
  {
    icon: <InstagramIcon sx={{ color: COLORS.TEXT_WHITE }} />,
    link: "https://instagram.com/tradesocio?igshid=YWJhMjlhZTc=",
  },
];

function getSocialLinks(linksList) {
  return linksList.map((socialLink, index) => {
    const key = `social-link${index}`;
    return (
      <IconButton key={key} href={socialLink.link} target="_blank">
        {socialLink.icon}
      </IconButton>
    );
  });
}
function Footer() {
  return (
    <FooterContainer component="footer">
      <FooterTypography>
        &#169; Tradesocio Copyright 2022. All Rights Reserved.
      </FooterTypography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ gap: { xs: 1, md: 2 } }}
      >
        <FooterTypography component={HashLink} to="/privacy-policy#">
          Privacy Policy
        </FooterTypography>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: COLORS.TEXT_WHITE, height: "1.125rem" }}
        />
        <FooterTypography component={HashLink} to="/legal-disclaimer#">
          Legal Disclaimer
        </FooterTypography>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: COLORS.TEXT_WHITE, height: "1.125rem" }}
        />
        <FooterTypography component={HashLink} to="/terms-of-service#">
          Terms of Service
        </FooterTypography>
      </Stack>
      <Stack direction="row" gap={2}>
        {getSocialLinks(FOOTER_SOCIAL_LINKS)}
      </Stack>
    </FooterContainer>
  );
}

export default Footer;
