import React from "react";
import { Container, Typography } from "@mui/material";
import { InvestmentContainer, InvestmentContent } from "./Investment.style";

function Investment({ sectionData }) {
  return (
    <InvestmentContainer>
      <Container maxWidth="lg">
        <Typography variant="h2" sx={{ fontSize: { xs: "2rem", md: "3rem" } }}>
          {sectionData.title}
        </Typography>
        <InvestmentContent variant="subtitle2">
          {sectionData.content}
        </InvestmentContent>
      </Container>
    </InvestmentContainer>
  );
}

export default Investment;
