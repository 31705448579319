import React from "react";
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  CeoContainer,
  CeoContent,
  CeoContentDesc,
  CeoContentDescContent,
  CeoContentDescSubtitle,
  CeoContentDescTitle,
  CeoContentImg,
  CeoContentImgCeo,
} from "./Ceo.style";

function Ceo({ sectionData }) {
  const theme = useTheme();
  const matched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container>
      <CeoContent>
        <CeoContainer>
          <Grid container direction={matched ? "column" : "row"}>
            <Grid item md={6}>
              <CeoContentDesc sx={{ margin: matched ? "auto" : "intial" }}>
                <CeoContentDescTitle variant="h2">
                  {sectionData.title}
                </CeoContentDescTitle>
                <CeoContentDescSubtitle variant="body2">
                  {sectionData.subTitle}
                </CeoContentDescSubtitle>
                <CeoContentDescContent variant="body2">
                  {sectionData.content}
                </CeoContentDescContent>
              </CeoContentDesc>
            </Grid>
            <Grid item md={6}>
              <CeoContentImg>
                <CeoContentImgCeo src={sectionData.img.src} />
              </CeoContentImg>
            </Grid>
          </Grid>
        </CeoContainer>
      </CeoContent>
    </Container>
  );
}

export default Ceo;
