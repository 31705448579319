import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import { UseCaseContent, UseCaseContentContainer } from "./useCase.style";

function UseCase({ sectionData }) {
  const content = sectionData.title?.map((title, index) => (
    <Box key={title}>
      <Typography
        variant="h3"
        sx={{
          fontSize: {
            xs: "3rem",
            sm: "5rem",
          },
        }}
      >
        {title.toUpperCase()}
      </Typography>
      <UseCaseContentContainer>
        <UseCaseContent>{sectionData.content[index]}</UseCaseContent>
      </UseCaseContentContainer>
    </Box>
  ));
  return (
    <Box component="section">
      <Container maxWidth="xl">
        <Grid container justifyContent="center" rowGap={8} columnSpacing={4}>
          <Grid item xs={12} md={6} display="flex" alignItems="end">
            <Typography
              variant="h1"
              component="h2"
              mb={9}
              sx={{ fontSize: "3.9375rem" }}
            >
              {sectionData.mainTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={sectionData.media.src}
              alt={sectionData.media.alt}
              sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Grid>
        </Grid>
        {content}
      </Container>
    </Box>
  );
}

export default UseCase;
