import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
import {
  UserInterfaceContainer,
  UserInterfaceContentContainer,
  UserInterfaceDesc,
  UserInterfaceImg,
} from "./userInterface.style";

function UserInterface({ sectionData }) {
  return (
    <Box>
      <UserInterfaceContainer>
        <Grid container>
          <Grid item md={6} xs={12}>
            <UserInterfaceImg component="img" src={sectionData.img.src} />
          </Grid>
          <Grid item md={6} xs={12}>
            <UserInterfaceContentContainer>
              <Typography
                variant="h2"
                sx={{ fontSize: "6rem", marginTop: "2rem" }}
                textAlign="start"
              >
                {sectionData.title}
              </Typography>
              <UserInterfaceDesc variant="subtitle2">
                {sectionData.content}
              </UserInterfaceDesc>
            </UserInterfaceContentContainer>
          </Grid>
        </Grid>
      </UserInterfaceContainer>
    </Box>
  );
}

export default UserInterface;
