import { Box, Grid } from "@mui/material";
import SectionTitle from "components/SectionTitle/sectionTitle.component";
// eslint-disable-next-line import/named
import { FeaturesContainer } from "./features.style";
import Feature from "./Feature.component";

function Features({ sectionData }) {
  const items = sectionData.title.map((element, index) => {
    return (
      <Grid
        item
        key={sectionData.title[index]}
        md={5}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        margin={index === 2 || 4 ? "auto" : "init"}
      >
        <Feature
          img={sectionData.images[index]}
          title={sectionData.title[index]}
          content={sectionData.desc[index]}
        />
      </Grid>
    );
  });
  return (
    <Box>
      <FeaturesContainer>
        <SectionTitle title={sectionData.mainTitle} maxWidth="75%" />
        <Box display="flex" justifyContent="center" mt={6}>
          <Grid container justifyContent="space-between" rowSpacing={5}>
            {items}
          </Grid>
        </Box>
      </FeaturesContainer>
    </Box>
  );
}

export default Features;
