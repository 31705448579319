import { Box, Stack, Typography, styled } from "@mui/material";
import { COLORS } from "styles/theme";

export const GettingStartedContainer = styled(Box)(() => ({
  minHeight: "452px",
}));
export const GettingStartedLogo = styled(Box)(() => ({
  maxWidth: "393px",
}));
export const GettingStartedLogoImg = styled("img")(() => ({
  width: "100%",
  height: "auto",
}));
export const GettingStartedContent = styled(Stack)(({ theme }) => ({
  backgroundColor: COLORS.PRIMARY_BLUE,
  minHeight: "452px",
  // paddingLeft: theme.breakpoints.up("md") ? theme.spacing(5) : "initial",
  // paddingRight: theme.breakpoints.up("md") ? theme.spacing(18) : "initial",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(18),
  [theme.breakpoints.down("md")]: {
    paddingLeft: "initial",
    paddingRight: "initial",
    textAlign: "center",
  },
}));
export const GettingStartedContentTitle = styled(Typography)(() => ({
  marginBottom: "1.5rem",
  fontWeight: "700",
  fontSize: "2.5rem",
}));
export const GettingStartedContentDesc = styled(Typography)(() => ({
  color: COLORS.TEXT_WHITE,
  lineHeight: "32.94px",
  fontSize: "1.125rem",
}));
