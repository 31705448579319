import React from "react";
import { Grid, Stack } from "@mui/material";

import {
  GettingStartedContainer,
  GettingStartedContent,
  GettingStartedContentDesc,
  GettingStartedContentTitle,
  GettingStartedLogo,
  GettingStartedLogoImg,
} from "./GettingStart.style";

function GettingStart({ sectionData }) {
  return (
    <GettingStartedContainer>
      <Grid container height="100%">
        <Grid item md={6} xs={12}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <GettingStartedLogo
              sx={{
                marginBottom: {
                  xs: "25px",
                  md: "initial",
                },
              }}
            >
              <GettingStartedLogoImg src={sectionData.img.src} />
            </GettingStartedLogo>
          </Stack>
        </Grid>
        <Grid item md={6}>
          <GettingStartedContent direction="column" justifyContent="center">
            <GettingStartedContentTitle variant="h2">
              {sectionData.title}
            </GettingStartedContentTitle>
            <GettingStartedContentDesc variant="subtitle2">
              {sectionData.content}
            </GettingStartedContentDesc>
          </GettingStartedContent>
        </Grid>
      </Grid>
    </GettingStartedContainer>
  );
}

export default GettingStart;
