import React from "react";

import { COLORS } from "styles/theme";

import {
  HeaderContent,
  HeaderFeaturesContainer,
  TypographyDes,
  TypographyTitle,
} from "./HeaderFeatures.styles";

function HeaderFeatures({ sectionData }) {
  return (
    <HeaderFeaturesContainer
      sx={{ backgroundImage: `url(${sectionData.img.src})` }}
    >
      <HeaderContent maxWidth="lg">
        <TypographyTitle variant="h1" color={COLORS.TEXT_WHITE}>
          {sectionData.title}
        </TypographyTitle>
        <TypographyDes color={COLORS.TEXT_WHITE} variant="subtitle2">
          {sectionData.content}
        </TypographyDes>
      </HeaderContent>
    </HeaderFeaturesContainer>
  );
}

export default HeaderFeatures;
