import { ListItemText, Stack, styled } from "@mui/material";
import { BORDERS, COLORS, SHADOWS } from "styles/theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const ArchitectureDescriptionCard = styled(Stack, {
  shouldForwardProp: (props) => props !== "cardIndex",
})(({ cardIndex, theme }) => ({
  width: "100%",
  height: "100%",
  backgroundColor: COLORS.TEXT_WHITE,
  boxShadow: SHADOWS.ARCHITECTURE_CARD,
  border: BORDERS.ARCHITECTURE_CARD,
  borderRadius: "14px",
  padding: theme.spacing(4),
  position: "absolute",
  transition: "all .2s",
  left: cardIndex % 2 === 0 ? "100%" : "-100%",
  [theme.breakpoints.down("md")]: {
    position: "static",
    padding: theme.spacing(4, 1),
    borderRadius: 0,
  },
}));
export const ListText = styled(ListItemText)(() => ({
  fontFamily: "Nexa Bold",
  color: COLORS.TEXT_BLACK,
  fontWeight: 700,
  fontSize: "1.25rem",
  lineHeight: "29.5px",
}));

export const MarkIcon = styled(FiberManualRecordIcon)(() => ({
  color: COLORS.TEXT_BLACK,
  fontSize: ".7rem",
}));
